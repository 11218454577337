import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { useGetAppOrSignUpHandler } from '../../../../../hooks/useGetAppOrSignUpHandler';
import * as S from './ProductDescription.styles';

export const ProductDescription = () => {
  const handleClickCta = useGetAppOrSignUpHandler();
  return (
    <S.GradientWrapper>
      <S.ScienceBg
        alt="science background"
        src="https://cdn.brain.fm/images/marketing/science_bg.webp"
      />
      <S.ProductDescriptionWrapper>
        <S.ProductiveContainer>
          <S.ProductiveImage
            alt="More track information"
            loading="lazy"
            src="https://cdn.brain.fm/images/marketing/more_track_information.webp"
          />
          <S.ProductiveMaxWidth>
            <S.Wrapper>
              <S.ProductiveContentPlaceholder />
              <S.ProductiveContent>
                <S.ProductiveTextContainer>
                  <S.Title>Peak productivity on demand</S.Title>
                  <S.Text>
                    Brain.fm’s focus music is made to help you work better, by blending into the background so you can focus distraction-free... 
                    all while stimulating the brain with gentle rhythmic pulses in the music that support sustained attention.<br /><br />
                    Other music is made to grab your attention, making it hard to think and work, even if you don’t realize it. Brain.fm’s functional music 
                    is designed from the bottom up to affect your brain and optimize your performance.
                  </S.Text>
                </S.ProductiveTextContainer>
                <S.ProductLinkStyled onClick={handleClickCta}>
                  <span>FOCUS WITH SCIENCE</span>
                  <img alt="arrow" src="https://cdn.brain.fm/icons/arrow-right.svg" />
                </S.ProductLinkStyled>
              </S.ProductiveContent>
            </S.Wrapper>
          </S.ProductiveMaxWidth>
        </S.ProductiveContainer>
      </S.ProductDescriptionWrapper>
      <S.ScienceDescriptionWrapper>
        <S.ScienceContainer>
          <S.Wrapper>
            <S.ScienceContent>
              <S.ScienceStaticData>
                <S.ScienceGeneralInfo>
                  <S.Title>Real science with<br />extraordinary results</S.Title>
                  <S.Text>Scientists at Brain.fm work with academic institutions to observe the effects of our technology on the brain using fMRI, EEG, 
                    and behavioral studies. We always include placebo groups in our tests to ensure our tech is what makes the difference.
                  </S.Text>
                </S.ScienceGeneralInfo>
                <S.LabelInfo>
                  <Image
                    alt="NSF BADGE"
                    height={96}
                    src="https://cdn.brain.fm/images/marketing/nsf_badge.webp"
                    width={335}
                  />
                </S.LabelInfo>
              </S.ScienceStaticData>
              <S.ScienceLinkContentStyled>
                <>
                  <Link href="/science">See our science</Link>
                  <img alt="arrow" src="https://cdn.brain.fm/icons/arrow-right.svg" />
                </>
              </S.ScienceLinkContentStyled>
            </S.ScienceContent>
          </S.Wrapper>
          <S.ScienceImage
            alt="Science EEG"
            loading="lazy"
            src="https://cdn.brain.fm/images/marketing/science_eeg.webp"
          />
          <S.ScienceLinkAfterContentStyled>
            <>
              <Link href="/science">See our science</Link>
              <img alt="arrow" src="https://cdn.brain.fm/icons/arrow-right.svg" />
            </>
          </S.ScienceLinkAfterContentStyled>
        </S.ScienceContainer>
      </S.ScienceDescriptionWrapper>
    </S.GradientWrapper>
  );
};
